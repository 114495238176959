import React from 'react';

const RightArrow = props => {
    return (
        <svg width={"36" || props.width } height={"25" || props.height } viewBox="0 0 36 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.933 0V10.6226H0V13.4743H25.7996V6.51141L31.8434 12.096L22.933 20.9601V25L36 12.0247L22.933 0Z" fill={ props.color || "white" }/>
        </svg>
    )
}

export default RightArrow;
