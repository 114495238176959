import React, {useState} from "react";
import styled from "styled-components";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import {Article} from "../components/Article";
import { ContainerCarousel, ContainerL } from "../ui/containers";
import CarouselAboutOffice from "../components/Carousel/CarouselAboutOffice";
import Modal from "../ui/modal";
import Seo from "../components/seo";
import  "../components/layout.css";

const IndentFooter = styled.div`
  margin-bottom: 250px;
  margin-top: 100px;
  @media (max-width: 568px) {
    margin-bottom: 320px;
    margin-top: 140px;
  }
`;


const ArticlePage = ({pageContext}) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleOpenModal = () => {
    if (!openModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    setOpenModal(!openModal);
  };

  return (
    <div>
      <Seo title={pageContext.title} />
      <Header />
      {
        openModal ?
          (<Modal toggleOpenModal={toggleOpenModal} />)
          :
          (
            <>
              <ContainerL>
                <Article data={pageContext} />
              </ContainerL>
              <IndentFooter>
                <ContainerCarousel>
                  <CarouselAboutOffice nocard items={pageContext.carousel} maxWidth={2000} />
                </ContainerCarousel>
              </IndentFooter>
              <Footer toggleOpenModal={toggleOpenModal} />
            </>
          )}

    </div>
  );
};
export default ArticlePage;
