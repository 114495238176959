import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Img = styled(GatsbyImage)`
  float: right;
  border-radius: 16px;
  width: 620px;
  opacity: 0.5;
  height: 620px;
  margin-left: 62px;
  margin-bottom: 81px;
  margin-top: 15px;

  @media (max-width: 568px) {
    width: 340px;
    height: 340px;
    margin-top: 5px;
    margin-bottom: 37px;
    margin: 0 10px 0 10px;
    float: none;
  }
  @media (max-width: 368px) {
    width: 260px;
    height: 260px;
    margin-top: 5px;
    margin-bottom: 37px;
    margin-left: 0;
    float: none;
  }
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  margin-top: 101px;
  color: #FFFFFF;
  white-space: pre-wrap;
  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 24px;
    margin-top: 37px;
  }
`;


const Title = styled.div`
  font-weight: 300;
  font-size: 72px;
  line-height: 96px;
  
  /* identical to box height, or 133% */
  max-width: 905px;

  min-height: 248px;
  text-transform: uppercase;

  color: #FFFFFF;
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 29px;
  }
`;

const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 100px;
  @media (max-width: 600px) {
    display: block;
    margin-top: 30px;
  }
`;

const TextDate = styled.div`
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height, or 123% */
  margin-bottom: 24px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 5px;
    margin-top: 39px;
    text-align: end;
  }
  color: #636363;
`;

export const Article = ({ data }) => {
  const img = getImage(data.image.localFile)
    if(!data) return null;
    return (
        <div>
            <ContentTitle>
                <div>
                    <Title>{data.title}</Title>
                </div>
                <TextDate>{data.published_at}</TextDate>
            </ContentTitle>
            <Img image={img} alt={data.title + "image"}/>
            <Text>
                {data.description}
            </Text>
        </div>
    );
}
