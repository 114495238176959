import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import useViewport from './useViewport';

const SliderContainer = styled.div`
    position: relative;
    top: ${props => props.height < 437 ? 30 : 50}px;
    max-width:${props => props.maxWidth}px;
    width: 100%;
    min-height:${props => props.height || 750}px;
    background:rgb(11,10,10);
    @media (max-width:991px){
        margin-top:300px;
    }
    @media (min-width: ${props => props.maxWidth}px) {
        margin: 0 auto;
    }
`

const SliderItems = styled.div`
    width:  100%;
    overflow-x: hidden;
    overflow-y:hidden;
    display: flex;
    background:rgb(11,10,10);
    align-items:center;
    position:relative;
    min-height: inherit
`

const SliderImage = styled.img`
    width: ${props => props.width || 620}px;
    height: ${props => props.height || 447}px;
    transition: transform 0.5s, right 0.5s, opacity 0.5s;
    margin: 0px 90px;
    border-radius:10px;
    position:absolute;
    @media (max-width: 991px) {
        margin: 0px;
        width: 90%;
        height: auto;
    }
`

const NavButtonStyles = {
    width: "68px",
    height: "34px",
    paddingTop: "4px",
    borderRadius: "inherit",
    background: "inherit",
    borderColor: "inherit",
    zIndex: 5
}

const HideArrowDiv = styled.div`
    background:#1890ff;
    border-color:#1890ff;
    height:50%;
    zIndex: 5;
    border-radius: ${props => props.direction === "left" ? "8px 8px 0 0" : "0 0 8px 8px"};
    :hover{
        background:white;
        border-color:white;
    }
    @media (max-width: 991px) {
        display:none;
    }
`

const RadioControl = styled.div`
    margin:35px auto;
    display:flex;
    gap:20px;
    justify-content:center;
    padding:10px 0px 50px;
`

const RadioDiv = styled.div`
    cursor:pointer;
    width:10px;
    height:10px;
    box-shadow: ${ props => props.selected ? "0px 0px 8px 2px rgba(66, 133, 244, 0.2)" : "0px 0px 8px 2px rgba(0, 0, 0, 0.2)" };
    background:${ props => props.selected ? "#4285F4" : "#373333" };
`

const TestDiv = styled.div`
    position:absolute;
    right:${props => props.minDx + 90 - (props.width * 1.4 - props.width) / 2}px;
    width:${props => props.width * 1.4}px;
    display: flex;
    align-items:center;
    z-index:100;
`

const ArrowButton = styled.div`
    position:absolute;
    right:-53.5px;
    width: 68px;
    height: 68px;
`

const InfoCard = styled.div`
    width: ${props => props.width || 400}px;
    min-width: 255px;
    height: ${props => props.height || 447}px;
    min-height: 325px;
    position:absolute;
    left: ${props => -props.width * 0.868}px;
    background: #4285F4;
    color:white;
    border-radius: 16px;
    padding: ${props => props.width < 360 ? "28px 18px" : "50px 42px"};
`

const SmallInfoCard = styled.div`
    width: 255px;
    min-width: 255px;
    height: 290px;
    position:absolute;
    left: 15px;
    top: -210px;
    background: #4285F4;
    color:white;
    border-radius: 16px;
    padding: 28px 18px;
    z-index: 10;
`

const InfoCardTitle = styled.div`
    font-size: ${props => props.width < 360 ? "18" : "24"}px;
    font-weight: 500;
    padding-bottom:${props => props.width < 360 ? "0" : "10"}px;
`

const InfoCardLine = styled.div`
    max-width: 280px;
    font-size:${props => props.width < 360 ? "15" : "20"}px;
    margin:${props => props.width < 360 ? "15px 0" : "28px 0"};
    font-weight: 300;
`

const calcMinDx = width => {
    return 180;
    /*
    if(width > 2000) return width * 0.336
    else if(width > 1800) return width * 0.234;
    else if(width > 1200) return 180;
    else if(width < 1200) return 110;
    */
}
let minDx = 180;
const CarouselAboutOffice = ({maxWidth = 2000, items, contacts, nocard = false}) => {
    console.log(items)
    const screens = useBreakpoint();
    let dx = 785;
    const renderSlider = () => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if(width > maxWidth) width = maxWidth;
            if(el.tagName === "DIV") return;
            minDx = calcMinDx(width);

            if(width < 992) {
                dx *= 2;
                el.style = `right: ${ count === selectedItem ? width * 0.1 / 2 : dx * selectedItem - dx * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
            }
            else el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px; ${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
    }

    useEffect(() => {
        sliderRef.current.childNodes.forEach((el, count) => {
            let width = window.innerWidth;
            if(width > maxWidth) width = maxWidth;
            if(el.tagName === "DIV") return;
            minDx = calcMinDx(width);

            if(width < 992) {
                el.style = `right: ${ count === selectedItem ? width * 0.1 / 2 : 1200 * selectedItem - 1200 * count}px; ${count === selectedItem ? 'z-index:2' : ''}`;
            }
            else el.style = `right: ${(dx * selectedItem - dx * count) + minDx}px; ${count === selectedItem ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        window.addEventListener("resize", renderSlider);
        return () => window.removeEventListener("resize", renderSlider);
    })

    const sliderRef = useRef(null);
    const [selectedItem, setSelectedItem] = useState(0);
    const [hoverArrow, setHoverArrow] = useState(null);
    const [touchStart, setTouchStart] = useState(null);
    const [touchMove, setTouchMove] = useState(null);
    let { width } = useViewport();
    if(width === 1600 && typeof window !== "undefined") width = window.innerWidth;
    if(width > maxWidth) width = maxWidth;
    let sliderImageWidth = Math.round(width / 2.58);
    if(sliderImageWidth > 620) sliderImageWidth = 620;
    const koefHeight = 0.72;
    dx = sliderImageWidth * 1.265;

    const sliderImageHeight = sliderImageWidth * koefHeight;

    minDx = calcMinDx(width);
    if(width < 992) dx *= 2.5;


    const nextHandler = () => {
        if(selectedItem === items.length - 1) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem + 1) - count * dx) + minDx}px; ${count === selectedItem + 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })

        setSelectedItem(selectedItem + 1)
    }

    const prevHandler = () => {
        if(selectedItem === 0) return;
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            el.style = `right: ${(dx * (selectedItem - 1) - count * dx) + minDx}px; ${count === selectedItem - 1 ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        setSelectedItem(selectedItem - 1)
    }

    const handleRadioClick = (index) => {
        sliderRef.current.childNodes.forEach((el, count) => {
            if(el.tagName === "DIV") return;
            if(!screens.lg) el.style = `right: ${ count === index ? width * 0.1 / 2 : 1200 * index - 1200 * count}px; ${count === index ? 'z-index:2' : ''}`;
            else el.style = `right: ${(dx * index - count * dx) + minDx}px; ${count === index ? 'transform:scale(1.4); z-index:2' : 'opacity: 0.4'}`;
        })
        setSelectedItem(index)
    }

    const handleMouseEnter = direction => {
        setHoverArrow(direction);
    }

    const handleMouseLeave = () => setHoverArrow(null);

    const handleTouchEnd = () => {
        if(!touchMove) return;
        if(touchStart > touchMove){
            if(selectedItem === items.length - 1) return;
            handleRadioClick(selectedItem + 1)
        }else{
            if(selectedItem === 0) return;
            handleRadioClick(selectedItem - 1)
        }
        setTouchMove(null)
    }

    return (
        <SliderContainer maxWidth={maxWidth} height={sliderImageHeight * 1.4 + 250} onTouchStart={(e) => setTouchStart(e.touches[0].pageX)} onTouchMove={(e) => setTouchMove(e.touches[0].pageX)} onTouchEnd={handleTouchEnd} >

            {
                !nocard && !screens.lg &&
                <SmallInfoCard>
                    <InfoCardTitle width={sliderImageWidth * 0.645}>ОФИС КОМПАНИИ <br></br>И ШОУРУМ</InfoCardTitle>
                    <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.schedule}</InfoCardLine>
                    <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.adress}</InfoCardLine>
                    <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.phone}</InfoCardLine>
                    <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.email}</InfoCardLine>
                </SmallInfoCard>
            }
            <SliderItems ref={sliderRef}>

                {
                    items && items.map((item, count) =>
                        <SliderImage height={sliderImageHeight} width={sliderImageWidth} key ={count} alt={count + "carousel image"} src={item.localFile.publicURL} />
                    )
                }

                <TestDiv minDx={minDx} width={sliderImageWidth}>
                    {
                        !nocard && screens.lg &&
                        <InfoCard width={sliderImageWidth * 0.645} height={sliderImageHeight}>
                            <InfoCardTitle width={sliderImageWidth * 0.645}>ОФИС КОМПАНИИ <br></br>И ШОУРУМ</InfoCardTitle>
                            <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.schedule}</InfoCardLine>
                            <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.adress}</InfoCardLine>
                            <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.phone}</InfoCardLine>
                            <InfoCardLine width={sliderImageWidth * 0.645}>{contacts.email}</InfoCardLine>
                        </InfoCard>
                    }


                    <ArrowButton>
                        <HideArrowDiv direction="left"  width={sliderImageWidth}>
                            <Button onClick={prevHandler} type="primary" onMouseEnter={() => handleMouseEnter("left")} onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                <LeftArrow color={ hoverArrow === "left" && "#4285F4" } />
                            </Button>
                        </HideArrowDiv>

                        <HideArrowDiv direction="right" width={sliderImageWidth}>
                            <Button onClick={nextHandler} type="primary" onMouseEnter={() => handleMouseEnter("right")} onMouseLeave={handleMouseLeave} size="large" style={NavButtonStyles}>
                                <RightArrow color={ hoverArrow === "right" && "#4285F4" } />
                            </Button>
                        </HideArrowDiv>
                    </ArrowButton>

                </TestDiv>





            </SliderItems>

            {
                !screens.lg &&
                <RadioControl>
                    {
                        items && items.map((item, count) =>
                            <RadioDiv selected={count === selectedItem} key={count} onClick={ () => handleRadioClick(count) } />
                        )
                    }
                </RadioControl>
            }


        </SliderContainer>
    )
}

export default CarouselAboutOffice;
